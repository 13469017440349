:root {
    /* --primaryColor: #8efbff; */
    --primaryColor: #00ffff;
    --secondaryColor: #4b4b4b;
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url('./fonts/Poppins-ExtraLight.woff');
}

@font-face {
    font-family: 'Poppins-ExtraLightItalic';
    src: url('./fonts/Poppins-ExtraLightItalic.woff');
}

body {
    background-color: #1b1d21 !important;
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    font-family: 'Poppins-ExtraLightItalic', sans-serif !important;
    letter-spacing: 2px;
}

.loadingOverlay {
    position: fixed;
    z-index: 1;
    background-color: rgba(0,0,0,0.4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadingOverlay h1 {
    color: white;
}

.nftImage {
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    box-shadow: rgba(0,0,0,1) 0px 0px 3px;
    transition: 250ms;
}

.nftImage:hover {
    filter:brightness(50%);
    cursor: pointer;
}

.nftImage:active {
    filter: brightness(150%);
}

.washImageOverlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nftContainer {
    /* border: 2px var(--primaryColor) solid; */
    background-color: rgb(77, 77, 77);
    box-shadow: rgba(0,0,0,0.8) 0px 0px 6px;
    padding: 20px;
    display: grid;
    grid-gap: 20px;
    width: 80%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-radius: 5px;
    margin: 10px;
}

h1 {
    margin: 20px !important;
    font-size: 5vw !important;
    color:var(--primaryColor) !important;
    text-shadow: rgba(255, 255, 255, 0.233) 1px 2px 2px;
    transition: 250ms;
}

nav {
    margin: 0;
    padding-top: 10px;
    width: 99%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

nav h3 {
    color:white;
    letter-spacing: 3px;
    font-size: 2vw;
}

.logoimg {
    margin-right: 30px;
    width: 80px;
    height: 80px;
}

@media screen and (max-width: 600px) {
    .nftContainer {
        grid-template-columns: 1fr 1fr;
    }

    nav h3 {
        font-size: small;
    }
    /* .h1 {
        font-size: 2vw;
    } */
}

.ant-notification {
    color: black !important;
}